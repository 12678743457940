.navLink{
    color: black;
    text-decoration: none;
    font-size: larger;
    font-weight: 500;
    font-family: "Segoe UI";
    margin-right: 7px;
}
.navLink:hover{
    color: red;
}
.wel{
    margin-left: 8px;
    font-size: large;
}
.wel:hover{
    color: blue;
}

